import {request} from "./request";


export const getSurveysBySurveyGroupId = (surveyGroupId: string) => {
    return request(`survey/get-surveys?uuid=${surveyGroupId}`,);

}

export const getSurveyBySurveyGroupUuidAndSurveyUuid = (sgid: string | undefined, id: string | undefined) => {
    return request(`survey/get-survey?sgUuid=${sgid}&uuid=${id}`,);
}

export const updateResponse = (uuid: string | undefined, response: object) => {
    return request(`survey/response?uuid=${uuid}`, {method: 'PATCH', body: JSON.stringify({response})});
}

export const submitSurvey = (uuid: string | undefined, sgUuid: string | undefined) => {
    return request(`survey/submit?sgUuid=${sgUuid}&uuid=${uuid}`,{method: 'PATCH'});
}

export const getAdminSG = () => {
    return request(`survey-group`);
}

export const fetchSurveyReport = (uuid: string) => {
    return request(`openai/get-report/${uuid}`);
}

export const updateReport = (uuid: string, report: string) => {
    return request(`openai/update-report?uuid=${uuid}`, {method: 'PATCH', body: JSON.stringify({response : report})});
}

export const regenerateReport = (uuid: string) => {
    return request(`openai/regenerate/${uuid}`, {method: 'PATCH'});
}

export const fetchSurveyReports = (uuid: string) => {
    return request(`openai/get-reports/${uuid}`);
}

export const isTokenValid = () => {
    return request(`user/valid`, {textResponse: true});
}

export const createSurveyGroup = (student_name: string, language: string) => {
    return request(`survey-group`, {method: 'POST', body: JSON.stringify({student_name, language})});
}

export const getSurveyGroupByUUID = (uuid: string) => {
    return request(`survey-group/get-by-uuid?uuid=${uuid}`);
}