// @ts-nocheck
import {message} from "antd";

export const request = async (url = '', options = {}) : Promise<any> => {
    const token = localStorage.getItem('token') || undefined;
    const tokenString = token ? `Bearer ${token}` : undefined;
    const {
        headers: headersParams = {},
        config = {},
        ignored = false,
        textResponse = false,
        blobResponse = false,
        ...rest
    } = options;
    message.config({
        top: window.innerHeight / 3
    });
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': tokenString,
        ...headersParams,
    };

    Object.entries(headers).forEach(([key, value]) => {
        // remove undefined headers
        if (value === undefined) delete headers[key];
    });

    return new Promise((resolve, reject) => {
        fetch(ignored ? url : 'https://yutongguihua.com/api/' + url, {
            method: 'GET',
            ...rest,
            headers,
        })
            .then((res) => {
                if (config?.noHandler) resolve(res);
                return res;
            })
            .then((res) => {
                if(res && res?.status === 401) {
                    window.location.href = '/404'
                }
                return res;
            }) // based on response.status
            .then((response) => {
                if (textResponse) {
                    return response?.text();
                }
                if (blobResponse) {
                    return response?.blob();
                }
                return response?.json();
            })
            .then((res1) => {
                if(res1.statusCode && res1.statusCode !== 200) {
                    message.error(res1.message)
                }
                resolve(res1);
                return res1;
            })
            .catch((err) => {
                reject({
                    ...err.res,
                    message: err.message,
                });
            });
    });
}
